import {
  ApiResponse,
  FavoriteResponse,
  PlaybackResponse,
  Question,
  QuestionResponse,
  Review,
  ReviewResponse,
  Stats,
  TopRatedVideo,
  UserContentResponse,
  Video,
  VideoResponse,
} from "../types";
import { api } from "./apiClient";

// Videos
export const getNewReleases = async () => {
  const response = await api<ApiResponse<Video[]>>({
    method: "GET",
    url: "/api/video/new",
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch new videos.");
};

export const getLibraryVideos = async ({
  pageParam = 1,
  category,
}: {
  pageParam: number;
  category: string;
}) => {
  const response = await api<ApiResponse<VideoResponse>>({
    method: "GET",
    url: `/api/video/library?page=${pageParam}&limit=12&category=${category}`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch videos.");
};

export const getVideos = async (searchTerm: string) => {
  const response = await api<ApiResponse<VideoResponse>>({
    method: "GET",
    url: `/api/video?page=1&limit=20&search=${searchTerm}`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch videos.");
};

export const getVideoDetails = async (id: string) => {
  const response = await api<ApiResponse<Video>>({
    method: "GET",
    url: `/api/video/${id}`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch video details.");
};

export const getVideoPlayback = async (id: string, videoId: string) => {
  const response = await api<ApiResponse<PlaybackResponse>>({
    method: "GET",
    url: `/api/playback/${id}/${videoId}`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch video playback.");
};

export const getNextVideos = async ({
  category,
  serialNumber,
}: {
  category: string;
  serialNumber: number;
}) => {
  const response = await api<ApiResponse<Video[]>>({
    method: "GET",
    url: `/api/video/next?category=${category}&serialNumber=${serialNumber}`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch next videos.");
};

// Stats
export const getStats = async () => {
  const response = await api<ApiResponse<Stats>>({
    method: "GET",
    url: "/api/stats",
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch stats.");
};

// Reviews
export const getTopRatedVideos = async () => {
  const response = await api<ApiResponse<TopRatedVideo[]>>({
    method: "GET",
    url: "/api/review/top/rated/videos",
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch top rated videos.");
};

export const getVideoReviews = async ({
  pageParam = 1,
  videoId,
}: {
  pageParam: number;
  videoId: string;
}) => {
  const response = await api<ApiResponse<ReviewResponse>>({
    method: "GET",
    url: `/api/review/video/${videoId}?page=${pageParam}&limit=10`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch video reviews.");
};

export const addVideoReview = async (reviewData: {
  videoId: string;
  userId: string;
  videoTitle: string;
  rating: number;
  text: string;
  video: string;
}) => {
  const response = await api<ApiResponse<Review>>({
    method: "POST",
    url: `/api/review/${reviewData.userId}`,
    data: reviewData,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to add video review.");
};

// Favorites
export const getUserFavorites = async (id: string) => {
  const response = await api<ApiResponse<FavoriteResponse[]>>({
    method: "GET",
    url: `/api/favorite/user/${id}`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch user favorites.");
};

export const addToFavorites = async (videoData: {
  video: string;
  user: string;
}) => {
  const response = await api<ApiResponse<FavoriteResponse>>({
    method: "POST",
    url: `/api/favorite/${videoData.user}`,
    data: videoData,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to add video to favorites.");
};

export const removeFavorite = async (videoData: {
  id: string;
  userId: string;
}) => {
  const response = await api<ApiResponse<string>>({
    method: "DELETE",
    url: `/api/favorite/${videoData.userId}/${videoData.id}`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to remove video from favorites.");
};

// Questions
export const getVideoQuestions = async ({
  pageParam = 1,
  videoId,
}: {
  pageParam: number;
  videoId: string;
}) => {
  const response = await api<ApiResponse<QuestionResponse>>({
    method: "GET",
    url: `/api/question/video/${videoId}?page=${pageParam}&limit=10`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch video questions.");
};

export const addVideoQuestion = async (questionData: {
  videoId: string;
  text: string;
  title: string;
  userId: string;
  video: string;
}) => {
  const response = await api<ApiResponse<Question>>({
    method: "POST",
    url: `/api/question/${questionData.userId}`,
    data: questionData,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to add video question.");
};

export const sendReply = async (questionData: {
  questionId: string;
  text: string;
  userId: string;
  repliedOn: Date;
}) => {
  const response = await api<ApiResponse<Question>>({
    method: "PUT",
    url: `/api/question/${questionData.userId}/${questionData.questionId}`,
    data: questionData,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to add reply.");
};

export const deleteQuestion = async (questionData: {
  userId: string;
  id: string;
}) => {
  const response = await api<ApiResponse<string>>({
    method: "DELETE",
    url: `/api/question/${questionData.userId}/${questionData.id}`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to delete question.");
};

export const deleteReply = async (questionData: {
  userId: string;
  id: string;
  index: number;
}) => {
  const response = await api<ApiResponse<string>>({
    method: "DELETE",
    url: `/api/question/${questionData.userId}/${questionData.id}/${questionData.index}`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to delete reply.");
};

export const getUserQuestionsAndReviews = async ({
  pageParam = 1,
  id,
}: {
  pageParam: number;
  id: string;
}) => {
  const response = await api<ApiResponse<UserContentResponse>>({
    method: "GET",
    url: `/api/question/reviews/${id}?page=${pageParam}&limit=10`,
  });

  if (response && response.data) {
    return response.data;
  }

  throw new Error("Unable to fetch user content.");
};
